import {ImageService} from './image.service';
import {BehaviorSubject, firstValueFrom} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {FileStorageService} from './file-storage.service';
import {AnyProjectJobForm} from '../models/project-job-form';
import {FormUtils} from '../utils/form-utils';
import {ProjectJobAnswerMeta} from '../models/project-job-answer-meta';
import {NodeService} from './node.service';
import {WorkspaceConfigService} from './workspace-config.service';

@Injectable({
    providedIn: 'root'
})
export class FormImageService {
    private static STORAGE_KEY = 'formImageStore';

    private store$ = new BehaviorSubject<QueuedImage[]>(JSON.parse(localStorage.getItem(FormImageService.STORAGE_KEY) || '[]') || []);
    private pendingProcessQueuedImage$: Promise<void>|undefined;
    isUploading$ = new BehaviorSubject<boolean>(false);

    constructor(
        private imageService: ImageService,
        private fileStorage: FileStorageService,
        private nodeService: NodeService,
        private workspaceConfigService: WorkspaceConfigService

    ) {
        this.store$.pipe(debounceTime(100)).subscribe(store => {
            localStorage.setItem(FormImageService.STORAGE_KEY, JSON.stringify(store));
        });
    }

    async queueImage(uniqueId: string, imageBlob: Blob) {
        if (uniqueId === null || uniqueId === undefined) {
            throw new Error('null or undefined uniqueId provided');
        }

        try {
            // Save image to local file storage
            const storedFile = await this.fileStorage.storeImage(uniqueId, imageBlob);

            if (storedFile) {
                await this.queueStoredImage(uniqueId);
            }
        } catch (error) {
            console.error('Error during saving of image to file storage', error);
            throw error;
        }
    }

    async queueStoredImage(uniqueId: string) {
        const store = await this.getStore();
        const imageIndex = await this.findQueuedIndex(uniqueId);
        const image = {uuid: uniqueId, uploaded: false};

        if (imageIndex === -1) {
            store.push(image);
            this.store$.next(store);
        }
    }

    async removeQueuedImage(uniqueId: string) {
        try {
            // Remove image from local file storage
            await this.fileStorage.removeImage(uniqueId);

            await this.removeImageFromStore(uniqueId);
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    async removeImageFromStore(uniqueId: string) {
        const store = await this.getStore();
        this.store$.next(store.filter(image => image.uuid !== uniqueId));
    }

    async getImageUrl(uniqueId: string) {
        const existing = await this.fileStorage.checkImageExists(uniqueId);

        if (existing) {
            // Retrieve image url from local file storage
            return await this.fileStorage.getImageUri(uniqueId);
        } else {
            try {
                const workspaceConfig = await firstValueFrom(this.workspaceConfigService.workspaceConfig$);
                if (workspaceConfig === null) {
                    throw new Error('No workspace config available');
                }
                return `${workspaceConfig.apiHost}/blob/${uniqueId}`;
            } catch (error) {
                throw new Error('No workspace config available');
            }
        }
    }

    /**
     * Sync available store images to the backend
     */
    async processQueuedImages() {
        if (this.pendingProcessQueuedImage$) {
            await this.pendingProcessQueuedImage$;
            return;
        }

        this.pendingProcessQueuedImage$ = this.pendingProcessQueuedImage();
    }

    async findQueuedIndex(uniqueId: string) {
        const store = await this.getStore();
        return store.findIndex(it => it.uuid === uniqueId);
    }

    async downloadFormImages(forms: AnyProjectJobForm[]) {
        for (const form of forms) {
            const nodes = form.type === 'layeredJobForm' ? await this.nodeService.findNodesByJobFormId(form.id) : [];

            const imageUUIDs = FormUtils.getLatestImageUUIDsForForm(form, nodes);

            await this.downloadUUIDs(imageUUIDs);
        }
    }

    async downloadAnswerMetaImages(projectJobAnswerMeta: ProjectJobAnswerMeta[]) {
        const imageUUIDs = projectJobAnswerMeta.map((meta) => meta.photo_id);

        await this.downloadUUIDs(imageUUIDs);
    }

    // TODO: Remove downloaded meta images?
    async removeFormImages(form: AnyProjectJobForm) {
        const nodes = form.type === 'layeredJobForm' ? await this.nodeService.findNodesByJobFormId(form.id) : [];
        const imageUUIDs = FormUtils.getAllImageUUIDsForForm(form, nodes);

        for (const uuid of imageUUIDs) {
            const exists = await this.fileStorage.checkImageExists(uuid);

            if (exists) {
                await this.fileStorage.removeImage(uuid);
            }
        }
    }

    private async pendingProcessQueuedImage() {
        try {
            const queuedImages = await firstValueFrom(this.store$);
            const imageUuids = queuedImages
                .filter(it => !it.uploaded)
                .map(it => it.uuid);

            this.isUploading$.next(true);

            // Upload all answer images
            const uploadPromises = imageUuids
                .map(uniqueId => {
                    return this.fileStorage.checkImageExists(uniqueId)
                        .then(existsInFileStorage => {
                            if (existsInFileStorage) {
                                return this.fileStorage.readImageAsBlob(uniqueId);
                            }

                            return Promise.resolve(null);
                        })
                        .then(blob => {
                            if (blob !== null) {
                                return this.imageService.uploadImage(uniqueId, blob);
                            }

                            return null;
                        })
                        .then(uploadResponse => {
                            if ((uploadResponse?.id ?? false) !== false) {
                                this.removeImageFromStore(uniqueId);
                            }
                        });
                });

            // Will upload images with multiple connection simultaneously (handled by the browser)
            await Promise.all(uploadPromises);
        } catch (error) {
            console.error(error);
            throw error;
        } finally {
            this.isUploading$.next(false);
            await Promise.resolve(void 0);
            this.pendingProcessQueuedImage$ = undefined;
        }
    }

    private async downloadUUIDs(imageUUIDs: string[]) {
        for (const uuid of imageUUIDs) {
            const exists = await this.fileStorage.checkImageExists(uuid);

            if (!exists) {
                try {
                    const blob = await firstValueFrom(this.imageService.downloadImage(uuid));
                    await this.fileStorage.storeImage(uuid, blob);
                } catch (e) {
                    console.error('Failed to download image', e);
                }
            }
        }
    }

    private getStore(): Promise<QueuedImage[]> {
        return firstValueFrom(this.store$);
    }
}

interface QueuedImage {
    uuid: string;
    uploaded: boolean; // Indicates if the image is uploaded and available on the backend server
}
