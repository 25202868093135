// @ts-strict-ignore
import Konva from 'konva';
import {DrawComponent, DrawComponentActionShape, DrawComponentState} from './draw-component';
import Shape = Konva.Shape;
import Group = Konva.Group;

export abstract class PathDrawComponent extends DrawComponent {

    readonly draggablePoints: Konva.Group;

    constructor(
        uuid: string,
        shape: DrawComponentActionShape,
        state: DrawComponentState,
    ) {
        super(uuid, shape, state);

        this.draggablePoints = new Konva.Group().hide();
        this.drawingLayer.add(this.draggablePoints);
    }

    setState(newState: DrawComponentState, ignoredProperties: string[] = []) {
        super.setState(newState, ignoredProperties);

        if (this.draggablePoints) {
            this.clearDraggablePoints();
            this.addDraggablePoints();
        }

        if (this.transformer.visible()) {
            this.updateDeleteButton();
        }
    }

    showTransformTool() {
        super.showTransformTool();

        if (this.draggablePoints) {
            this.clearDraggablePoints();
            this.addDraggablePoints();
        }

        this.draggablePoints.zIndex(1);
        this.draggablePoints.show();
        this.updateDraggablePoints();
    }

    hideTransformTool() {
        super.hideTransformTool();
        this.draggablePoints.hide();
    }

    updateDraggablePoints() {
        this.draggablePoints.setAttrs({
            x: this.drawingShape.x(),
            y: this.drawingShape.y(),
            scaleX: this.drawingShape.scaleX(),
            scaleY: this.drawingShape.scaleY(),
            rotation: this.drawingShape.rotation()
        });

        this.draggablePoints.children.forEach((child) => {
            this.updateCircle(child);
        });

        const points = (this.drawingShape as Konva.Line | Konva.Arrow).points();
        this.draggablePoints.find('.pointInsert').forEach((child, index) => {
            child.setAttrs({
                strokeWidth: 1.5 / child.getAbsoluteScale().x,
                x: this.valueInBetween(points[index * 2], points[index * 2 + 2]),
                y: this.valueInBetween(points[index * 2 + 1], points[index * 2 + 3]),
            });
            if (child instanceof Konva.Group) {
                child.children.forEach((childElement) => {
                    switch (childElement.name()) {
                        case 'circle':
                            this.updateCircle(childElement);
                            break;
                        case 'line-v':
                            this.updateLineV(childElement);
                            break;
                        case 'line-h':
                            this.updateLineH(childElement);
                            break;
                    }
                });
            }
        });

        try { this.draggablePoints.draw(); } catch (e) {
            // NOOP
        }
    }

    addDraggablePoints() {
        let i = 0;
        const points = (this.drawingShape as Konva.Line | Konva.Arrow).points();

        while (i < points.length) {
            this.addDraggablePoint(points[i], points[i + 1], i, i + 1);

            const inBetweenX = this.valueInBetween(points[i], points[i + 2]);
            const inBetweenY = this.valueInBetween(points[i + 1], points[i + 3]);

            if (!isNaN(inBetweenX) && !isNaN(inBetweenY)) {
                this.addDraggablePointInsert(inBetweenX, inBetweenY, i + 2);
            }

            i += 2;
        }

        this.updateDraggablePoints();
    }

    addDraggablePoint(x: number, y: number, pointIndexX: number, pointIndexY: number) {
        const draggablePoint = new Konva.Circle({radius: 8, hitStrokeWidth: 12, fill: '#fff', draggable: true, x, y});

        draggablePoint
            .on('dragmove dragend', (event) => {
                const shape = this.drawingShape as Konva.Line | Konva.Arrow;
                const position = event.target.getPosition();
                const points = shape.points();
                points[pointIndexX] = position.x;
                points[pointIndexY] = position.y;
                shape.points(points);
                this.updateTransformer();
                this.updateState();
                this.draw();
            });

        this.draggablePoints.add(draggablePoint);

        this.updateDraggablePoints();
    }

    addDraggablePointInsert(x: number, y: number, insertIndex: number) {
        const draggablePointInsert = new Konva.Group({name: 'pointInsert', x, y});

        draggablePointInsert
            .add(new Konva.Circle({name: 'circle', radius: 8, hitStrokeWidth: 12, fill: '#000', stroke: '#fff', strokeWidth: 1.5}))
            .add(new Konva.Line({name: 'line-v', points: [0, -4, 0, 4], stroke: '#fff', strokeWidth: 1.5}))
            .add(new Konva.Line({name: 'line-h', points: [-4, 0, 4, 0], stroke: '#fff', strokeWidth: 1.5}))
            .on('click tap', () => {
                const shape = this.drawingShape as Konva.Line | Konva.Arrow;
                const points = shape.points();
                points.splice(insertIndex, 0, draggablePointInsert.x(), draggablePointInsert.y());
                shape.points(points);
                this.clearDraggablePoints();
                this.addDraggablePoints();
                this.onChange();
            });

        this.draggablePoints.add(draggablePointInsert);

        this.updateDraggablePoints();
    }

    updateTransformer() {
        super.updateTransformer();
    }

    private clearDraggablePoints() {
        this.draggablePoints.destroyChildren().draw();
    }

    private valueInBetween(value1: number, value2: number): number {
        return Math.round(Math.min(value1, value2) + ((Math.max(value1, value2) - Math.min(value1, value2)) / 2));
    }

    private updateCircle(circle: Group | Shape) {
        const scale = circle.getAbsoluteScale().x;

        circle.setAttrs({
            radius: 8 / scale,
            hitStrokeWidth: 12 / scale,
            strokeWidth: 1.5 / scale,
        });
    }

    private updateLineV(line: Group | Shape) {
        const scale = line.getAbsoluteScale().x;

        line.setAttrs({
            strokeWidth: 1.5 / scale,
            points: [0, -4 / scale, 0, 4 / scale],
        });
    }

    private updateLineH(line: Group | Shape) {
        const scale = line.getAbsoluteScale().x;

        line.setAttrs({
            strokeWidth: 1.5 / scale,
            points: [-4 / scale, 0, 4 / scale, 0],
        });
    }

    protected onClick(event: unknown) {
        this.showTransformTool();
    }

    protected onUpdate() {
        super.onUpdate();
        this.updateDraggablePoints();
    }

}
