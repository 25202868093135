import {Component} from '@angular/core';
import {FormControl, ValidatorFn, Validators} from '@angular/forms';
import {PopupRef} from '../../../helpers/popup-ref';
import {PopupConfig} from '../../../models/popup-config';

export type QuestionTypeTabularModalResult = {
    type: 'cancel' | 'delete'
} | {
    type: 'value',
    value: string
}

@Component({
    selector: 'app-question-type-tabular-modal',
    templateUrl: './question-type-tabular-modal.component.html'
})
export class QuestionTypeTabularModalComponent {
    readonly rowLabelControl = new FormControl('', {
        nonNullable: true,
        validators: [Validators.required]
    })

    set rowLabelValue(value: string) {
        this.rowLabelControl.setValue(value)
    }

    set rowLabelValidator(validator: ValidatorFn) {
        this.rowLabelControl.setValidators([
            Validators.required,
            validator
        ])
    }

    type: 'add' | 'edit' | 'delete' | undefined;

    constructor(
        private popupRef: PopupRef,
        {data}: PopupConfig<Partial<QuestionTypeTabularModalComponent>>
    ) {
        if (data) {
            if (data.type) {
                this.type = data.type;
            }
            this.rowLabelValue = data.rowLabelValue || '';
            if (data.rowLabelValidator) {
                this.rowLabelValidator = data.rowLabelValidator;
            }
        }
    }

    submit() {
        this.type && this.type !== 'delete' ? this.addOrUpdateRow() : this.deleteRow();
    }

    addOrUpdateRow() {
        this.rowLabelControl.markAsDirty();

        if (this.rowLabelControl.valid) {
            this.popupRef.close<QuestionTypeTabularModalResult>({
                type: 'value',
                value: this.rowLabelControl.value
            });
        }
    }

    deleteRow() {
        this.popupRef.close<QuestionTypeTabularModalResult>({
            type: 'delete'
        });
    }

    cancel() {
        this.popupRef.close<QuestionTypeTabularModalResult>({
            type: 'cancel'
        });
    }
}
